import { AdminPanelSettings, Info } from "@mui/icons-material";
import {
  Button,
  FormControl,
  FormLabel,
  Link,
  Option,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Tooltip,
} from "@mui/joy";
import type { ColumnDef, PaginationState } from "@tanstack/react-table";
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import type { DatePickerValue, DateRangePickerValue } from "@tremor/react";
import {
  BarChart,
  Card,
  DatePicker,
  Metric,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  Title,
} from "@tremor/react";
import {
  endOfDay,
  format,
  formatDate,
  isSameDay,
  startOfDay,
  subDays,
} from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import type { LlmName } from "../../../../backend/src/ai/llmMeta.ts";
import { LLM_META } from "../../../../backend/src/ai/llmMeta.ts";
import type {
  CreditTransaction,
  PerDayInput,
} from "../../../../backend/src/api/organization/credits/creditsTypes.ts";
import { useOrganization } from "../../lib/api/organization";
import { trpc } from "../../lib/api/trpc/trpc";
import { useMe } from "../../lib/api/user";
import { useTranslation } from "../../lib/i18n";
import getCurrentTimezone from "../../lib/util";
import { PHASE_TYPES } from "../auth/CreateOrganizationModal";
import { UserDisplay } from "../auth/UserMenu";
import LocalizedDateRangePicker from "../input/LocalizedDateRangePicker.tsx";
import { DelayedLoader } from "../util/DelayadLoader";
import { SettingsPage } from "./SettingsPage";

const useValueFormatter = function () {
  const { i18n } = useTranslation();
  return (n: number) =>
    `${n.toLocaleString(i18n.language, {
      maximumFractionDigits: n < 1 ? 1 : 0,
    })} Credits`;
};

export default function CostsPerDayChart() {
  const { t, i18n } = useTranslation();
  const [range, setRange] = useState<DateRangePickerValue>({
    from: subDays(new Date(), 7),
    to: new Date(),
    selectValue: "last7Days",
  });
  const [groupBy, setGroupBy] = useState<PerDayInput["groupBy"]>("model");

  const valueFormatter = useValueFormatter();
  const { data } = trpc.organization.credits.perDay.useQuery({
    from: range.from ? startOfDay(range.from) : undefined,
    to: range.to ? endOfDay(range.to) : undefined,
    timezone: getCurrentTimezone(),
    groupBy,
  });
  const { chartData, categories } = useMemo(() => {
    if (!data) return { chartData: [], categories: [] };

    function formatPointValue(value: string) {
      switch (groupBy) {
        case "model": {
          const modelName = LLM_META[value as LlmName]?.name;
          if (modelName) {
            return modelName;
          }
          if (i18n.exists(`images.model.${value}`)) {
            return t(`images.model.${value}`);
          }
          if (i18n.exists(`tools.meetingTranscription.models.${value}`)) {
            return t(`tools.meetingTranscription.models.${value}`);
          }

          return t("other");
        }
        case "tool":
          return t(`credits.transactions.type.${value}`);
      }
    }

    const c = new Set<string>();
    const chart = Object.entries(data).map(([day, points]) => {
      const dayData: Record<string, number | string> = {
        date: formatDate(new Date(day), "P"),
      };
      for (const point of points) {
        const formattedValue = formatPointValue(point.value);
        c.add(formattedValue);
        dayData[formattedValue] = point.totalCredits;
      }
      return dayData;
    });

    return { chartData: chart, categories: Array.from(c) };
  }, [data, groupBy, t]);

  const selectOptions = {
    model: t("credits.chart.options.byModel"),
    tool: t("credits.chart.options.byTool"),
  } as const;

  return (
    <Card>
      <div className="flex w-full flex-col">
        <div className="mb-2 flex items-center justify-between">
          <Title>{t("credits.chart.title")}</Title>
          <FormControl
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
            }}
          >
            <LocalizedDateRangePicker
              value={range}
              onValueChange={setRange}
              minDate={new Date(2023, 11, 1)}
              maxDate={new Date()}
            />
            <Select
              value={groupBy}
              size="sm"
              sx={{ minWidth: 130 }}
              onChange={(_, option) => setGroupBy(option!)}
            >
              {Object.entries(selectOptions).map(([value, label]) => (
                <Option key={value} value={value}>
                  {label}
                </Option>
              ))}
            </Select>
          </FormControl>
        </div>
        <BarChart
          data={chartData ?? []}
          index="date"
          yAxisWidth={65}
          categories={categories}
          valueFormatter={valueFormatter}
          stack
        />
      </div>
    </Card>
  );
}

const TransactionTable = () => {
  const { t } = useTranslation();
  const columns: ColumnDef<CreditTransaction>[] = useMemo(
    () => [
      {
        accessorKey: "amount",
        header: t(`credits.transactions.amount.header`),
      },
      {
        accessorKey: "userId",
        header: t(`users`),
        cell: ({ row }) => <UserDisplay userId={row.getValue("userId")} />,
      },
      {
        accessorKey: "timestamp",
        header: t(`credits.transactions.date.header`),
        cell: ({ row }) => {
          const usedAt = row.getValue("timestamp") as string;
          return format(new Date(usedAt), "yyyy-MM-dd HH:mm:ss");
        },
      },
    ],
    [t]
  );

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data } = trpc.organization.credits.transactions.useQuery({
    page: pagination.pageIndex + 1,
    pageSize: pagination.pageSize,
  });

  const table = useReactTable({
    columns,
    data: data?.transactions ?? [],
    getCoreRowModel: getCoreRowModel(),
    pageCount: data ? Math.ceil(data.totalCount / pagination.pageSize) : 1,
    getPaginationRowModel: getPaginationRowModel(),
    state: { pagination },
    onPaginationChange: setPagination,
    manualPagination: true,
  });

  return (
    <Card>
      <Title>{t("credits.transactions.title")}</Title>
      <Table>
        <TableHead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow
              key={headerGroup.id}
              className="border-b border-tremor-border"
            >
              {headerGroup.headers.map((header) => {
                return (
                  <TableHeaderCell key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHeaderCell>
                );
              })}
            </TableRow>
          ))}
        </TableHead>

        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && "selected"}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <div className="flex items-center justify-end space-x-2 py-4">
        <Button
          size="sm"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          Previous
        </Button>
        <Button
          size="sm"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          Next
        </Button>
      </div>
    </Card>
  );
};

const CREDITS_DOCS_URL = "https://docs.meingpt.com/platform/credits";

export const CreditDisplay = () => {
  const { t } = useTranslation();
  const { data: creditsUsage } = trpc.organization.credits.usage.useQuery();
  const creditsMutation = trpc.organization.credits.addCredits.useMutation();
  const utils = trpc.useUtils();
  const me = useMe();

  if (!creditsUsage) return <DelayedLoader />;

  const addCredit = () => {
    const amount = parseFloat(
      prompt("Enter the amount of credits to add") ?? ""
    );
    if (isNaN(amount)) {
      alert("Please enter a valid number");
      return;
    }
    const confirm = parseFloat(
      prompt("Please retype the amount to confirm") ?? ""
    );
    if (amount !== confirm) {
      alert("Amounts do not match");
      return;
    }
    const comment = prompt("Enter a comment (optional)");
    creditsMutation
      .mutateAsync({
        creditAmount: amount,
        comment: comment ?? undefined,
      })
      .then(() => {
        void utils.organization.credits.invalidate();
        toast.success(amount + " credits added");
      })
      .catch(() => {
        toast.error("Could not add credits");
      });
  };

  return (
    <SettingsPage title={<CreditsHeader />} sx={{ px: 1 }}>
      <Card className="flex items-center justify-between">
        <div>
          <Title>{t("credits.balance")}</Title>
          <span className="flex items-end space-x-1">
            <Metric>{creditsUsage.balance.toFixed(2)} Credits</Metric>
          </span>
          <Link href={CREDITS_DOCS_URL} target="_blank" rel="noreferrer">
            {t("moreInfo")}
          </Link>
        </div>
        {me?.isSuperUser && (
          <Button onClick={addCredit} startDecorator={<AdminPanelSettings />}>
            {t("addCredits")}
          </Button>
        )}
      </Card>
      <PhaseSettings />
      <CostsPerDayChart />
      <TransactionTable />
    </SettingsPage>
  );
};

function CreditsHeader() {
  const { t } = useTranslation();
  return (
    <div className="flex flex-row gap-2">
      <span>{t("settings.tabs.credits")}</span>
      <a
        href={CREDITS_DOCS_URL}
        target="_blank"
        rel="noreferrer"
        style={{
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          "--Icon-fontSize": "1.4rem",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Tooltip title="Dokumentation" placement="right" size="sm">
          <Info fontSize="small" />
        </Tooltip>
      </a>
    </div>
  );
}

function PhaseSettings() {
  const [phase, setPhase] = useState(PHASE_TYPES[0].type);
  const [dateRange, setDateRange] = useState<{
    start: DatePickerValue;
    end: DatePickerValue;
  }>({ start: new Date(), end: new Date() });

  const organisation = useOrganization();
  const me = useMe();
  const { t } = useTranslation();

  const { mutateAsync: updateOrganization, isPending } =
    trpc.organization.mutateOrganization.useMutation();
  const utils = trpc.useUtils();

  const canUpdate = useMemo(() => {
    if (!organisation) return false;
    const {
      phaseStartDate: initStart,
      phaseEndDate: initEnd,
      phase: initPhase,
    } = organisation;

    let updatedDate = false;
    if (initStart && initEnd) {
      updatedDate = !(
        isSameDay(new Date(initStart), new Date(dateRange.start!)) &&
        isSameDay(new Date(initEnd), new Date(dateRange.end!))
      );
    }

    return initPhase !== phase || updatedDate;
  }, [organisation, phase, dateRange]);

  useEffect(() => {
    if (!organisation) return;
    const { phaseStartDate, phaseEndDate, phase: currentPhase } = organisation;
    setPhase(currentPhase);

    if (currentPhase === "NORMAL" || !phaseEndDate || !phaseStartDate) return;
    setDateRange({
      start: new Date(phaseStartDate),
      end: new Date(phaseEndDate),
    });
  }, [organisation]);

  const handleUpdate = async () => {
    if (!organisation) return;
    const { phase: initPhase } = organisation;

    try {
      const { start, end } = dateRange;
      await updateOrganization({
        phase: phase !== initPhase ? phase : undefined,
        phaseStartDate: start!.toISOString(),
        phaseEndDate: end!.toISOString(),
      });
      await utils.organization.invalidate();
    } catch (_) {
      toast.error("Fehler beim Phase update");
    }
  };

  if (organisation?.phase === "NORMAL") return;

  return (
    <Card>
      <Title>{t("credits.phase.title")}</Title>
      <Stack direction="row" flex={1} justifyContent="space-between">
        <Stack gap={1} mt={2}>
          <RadioGroup orientation="horizontal">
            {PHASE_TYPES.map(({ type, text }) => (
              <Radio
                onChange={() => setPhase(type)}
                key={type}
                checked={phase === type}
                value={text}
                label={text}
              />
            ))}
          </RadioGroup>
          <Stack direction="row" gap={2} sx={{ mt: 1, width: 500 }} flex={1}>
            <FormControl sx={{ flex: 1 }}>
              <FormLabel>{t("createOrg.startDate")}</FormLabel>
              <DatePicker
                enableClear={false}
                value={dateRange.start}
                onValueChange={(start) =>
                  setDateRange((prev) => ({ ...prev, start }))
                }
              />
            </FormControl>

            <FormControl sx={{ flex: 1 }}>
              <FormLabel>{t("createOrg.endDate")}</FormLabel>
              <DatePicker
                enableClear={false}
                value={dateRange.end}
                onValueChange={(end) =>
                  setDateRange((prev) => ({ ...prev, end }))
                }
              />
            </FormControl>
          </Stack>
        </Stack>
        {me?.isSuperUser && (
          <Button
            disabled={!canUpdate}
            sx={{ my: "auto" }}
            onClick={handleUpdate}
            startDecorator={<AdminPanelSettings />}
            loading={isPending}
          >
            {t("credits.phase.update")}
          </Button>
        )}
      </Stack>
    </Card>
  );
}
