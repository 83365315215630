import {
  Language,
  Mic,
  Summarize,
  Translate,
  Wallpaper,
} from "@mui/icons-material";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { IconButton, List, Typography } from "@mui/joy";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import type { LlmName } from "../../../../backend/src/ai/llmMeta.ts";
import { trpc } from "../../lib/api/trpc/trpc";
import { useTranslation } from "../../lib/i18n";
import { comparePath } from "../../lib/routeUtils";
import type { ModalPath, Path } from "../../router";
import { useModals, useNavigate, useParams } from "../../router";
import { TreeItem } from "./tree/LeafItem";
import { TRANSLATE_CONTENT_SIDEBAR_BUTTON_ID } from "../../lib/testIds.ts";

type Tool = {
  name: string;
  icon: React.ReactNode;
  enabled: boolean;
  testId?: string;
} & (
  | {
      path: Path;
    }
  | {
      modal: ModalPath;
    }
);

export default function ToolsList({
  sidebarCollapsed,
}: {
  sidebarCollapsed?: boolean;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams("/:organizationId");
  const path = useLocation().pathname;
  const modals = useModals();

  const { data: configuredImageModels } =
    trpc.tools.images.listConfigured.useQuery();
  const { data: enabledModels } = trpc.modelConfig.getEnabled.useQuery();
  const { data: productConfig } = trpc.productConfig.get.useQuery();
  const { data: textTranslationEnabled } =
    trpc.tools.translateContent.textTranslator.isEnabled.useQuery();
  const { data: documentTranslatorEnabled } =
    trpc.tools.translateContent.documentTranslator.isEnabled.useQuery();

  const tools: Tool[] = useMemo(
    () =>
      (
        [
          {
            name: t("generateImage"),
            icon: <Wallpaper fontSize="small" />,
            path: "/:organizationId/tools/imageFactory",
            enabled: Boolean(
              productConfig?.imageGeneration && !!configuredImageModels?.length
            ),
            testId: "imageGenerationSidebarButton",
          },
          {
            name: t("researchAssistant"),
            icon: <Language fontSize="small" />,
            path: "/:organizationId/tools/researchAssistant",
            enabled: !!enabledModels?.includes(
              "llama-3.1-sonar-large-128k-online" satisfies LlmName
            ),
          },
          {
            name: t("transcriptSummarizer"),
            icon: <Summarize fontSize="small" />,
            path: "/:organizationId/tools/transcriptSummarizer",
            enabled: Boolean(
              productConfig?.meetingSummarizer &&
                enabledModels?.includes("gemini-1.5-pro" as LlmName)
            ),
          },
          {
            name: "Meeting Aufnehmen",
            icon: <Mic fontSize="small" />,
            path: "/:organizationId/tools/meetingRecorder",
            enabled: Boolean(productConfig?.meetingTranscription),
          },
          {
            name: t("supportAssistant.title"),
            icon: <SupportAgentIcon fontSize="small" />,
            path: "/:organizationId/tools/supportAssistant",
            enabled: Boolean(
              productConfig?.helpdesk &&
                enabledModels?.includes("claude-3-5-sonnet" as LlmName)
            ),
          },
          {
            name: t("translateContent"),
            icon: <Translate fontSize="small" />,
            path: "/:organizationId/tools/translateContent",
            enabled: !!textTranslationEnabled || !!documentTranslatorEnabled,
            testId: TRANSLATE_CONTENT_SIDEBAR_BUTTON_ID,
          },
        ] satisfies Tool[]
      ).filter((tool) => tool.enabled),
    [
      t,
      enabledModels,
      configuredImageModels,
      textTranslationEnabled,
      productConfig,
      documentTranslatorEnabled,
    ]
  );

  if (tools.length === 0) return null;

  if (sidebarCollapsed) {
    return tools.map((tool) => (
      <IconButton
        key={tool.name}
        data-testid={tool.testId}
        style={{
          backgroundColor:
            "path" in tool && comparePath(path, tool.path)
              ? "var(--joy-palette-neutral-plainActiveBg)"
              : "transparent",
        }}
        onClick={() => {
          "path" in tool &&
            navigate(tool.path, {
              params,
            });
          "modal" in tool &&
            modals.open(tool.modal, {
              params,
            });
        }}
      >
        {tool.icon}
      </IconButton>
    ));
  }

  return (
    <>
      <Typography
        level="title-md"
        color="neutral"
        sx={{
          px: 2,
          py: 1,
        }}
      >
        {t("aiTools")}
      </Typography>
      <List size={sidebarCollapsed ? "md" : "sm"}>
        {tools.map((tool) => (
          <TreeItem
            selected={"path" in tool ? comparePath(path, tool.path) : false}
            key={tool.name}
            icon={tool.icon}
            name={tool.name}
            testId={tool.testId}
            onClick={() => {
              if ("modal" in tool) {
                modals.open(tool.modal, {
                  params,
                });
                return;
              } else {
                navigate(tool.path, {
                  params,
                });
              }
            }}
          />
        ))}
      </List>
    </>
  );
}
