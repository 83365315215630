import { Button, Card, Checkbox, Typography } from "@mui/joy";
import { useTranslation } from "../../../lib/i18n";
import { DocumentDropzone } from "../../../components/chat/attachments/DocumentDropzone";
import { useCallback, useRef, useState } from "react";
import { MarkdownRenderer } from "../../../components/chat/MarkdownRenderer";
import { SegmentDisplay } from "../../../components/input/SegmentDisplay";
import { useDownloadAsDoc } from "../../../lib/documentHooks";
import wordIcon from "../../../assets/word_icon.png";
import { Email } from "@mui/icons-material";
import { trpc } from "../../../lib/api/trpc/trpc";
import { useSmoothTypingText } from "../../../components/chat/useSmoothTypingText";
import ToolPage from "../../../components/sidebar/ToolPage";

type TranscriptLength = "short" | "appropriate" | "comprehensive";

const componentMeta = {
  executiveSummary: {
    label: "Executive Summary",
    value: "executiveSummary",
  },
  participants: {
    label: "Participants",
    value: "participants",
  },
  agenda: {
    label: "Agenda",
    value: "agenda",
  },
  actionItems: {
    label: "Action Items",
    value: "actionItems",
  },
};

export default function TranscriptSummarizer() {
  const { t, i18n } = useTranslation();

  const [uploadedFiles, setUploadedFiles] = useState<string[]>([]);
  const [selectedLength, setSelectedLength] =
    useState<TranscriptLength>("appropriate");
  const [components, setComponents] = useState<
    Record<keyof typeof componentMeta, boolean>
  >({
    executiveSummary: true,
    participants: true,
    agenda: true,
    actionItems: true,
  });
  const htmlRef = useRef<HTMLDivElement>(null);
  const [response, setResponse] = useState<string>("");
  const [generating, setGenerating] = useState<boolean>(false);

  const smoothedResponse = useSmoothTypingText(response);

  const { mutateAsync: summarizeMeeting } =
    trpc.tools.meetingSummarizer.summarize.useMutation();

  const onCreate = useCallback(async () => {
    if (!uploadedFiles.length) return;
    if (generating) return;

    setGenerating(true);

    const stream = await summarizeMeeting({
      documentId: uploadedFiles[0],
      length: selectedLength,
      components,
      language: i18n.language.split("-")[0] as "en" | "de",
    });

    for await (const chunk of stream) {
      setResponse(chunk.response);
    }

    setGenerating(false);
  }, [
    summarizeMeeting,
    uploadedFiles,
    selectedLength,
    generating,
    components,
    i18n.language,
  ]);

  const { downloadDocument } = useDownloadAsDoc({
    markdown: response,
    filename: "meeting_summary",
  });

  return (
    <ToolPage
      title={t("transcriptSummarizer")}
      subtitle={t("tools.transcriptSummarizer.welcome")}
    >
      <div className="w-max">
        <DocumentDropzone
          allowMultiple={false}
          documentIds={uploadedFiles}
          setDocumentIds={setUploadedFiles}
        />
      </div>
      <Card>
        <Typography level="title-lg">{t("settings.literal")}</Typography>
        <Typography level="title-md">
          {t("tools.transcriptSummarizer.settings.length.title")}
        </Typography>
        <SegmentDisplay
          size="sm"
          options={
            [
              {
                label: t("tools.transcriptSummarizer.settings.length.short"),
                value: "short",
              },
              {
                label: t(
                  "tools.transcriptSummarizer.settings.length.appropriate"
                ),
                value: "appropriate",
              },
              {
                label: t(
                  "tools.transcriptSummarizer.settings.length.comprehensive"
                ),
                value: "comprehensive",
              },
            ] as const
          }
          value={selectedLength}
          setValue={(value: TranscriptLength) => setSelectedLength(value)}
        />
        <Typography level="title-md">
          {t("tools.transcriptSummarizer.settings.components.title")}
        </Typography>
        {Object.entries(componentMeta).map(([key, { label, value }]) => (
          <Checkbox
            key={key}
            checked={components[value as keyof typeof componentMeta]}
            onChange={() => {
              setComponents((prev) => ({
                ...prev,
                [value]: !prev[value as keyof typeof componentMeta],
              }));
            }}
            label={t(label)}
          />
        ))}
      </Card>
      <div className="flex flex-row items-center gap-2">
        <Button
          onClick={onCreate}
          loading={generating}
          disabled={!uploadedFiles.length}
        >
          {response ? t("regenerate") : t("summarize")}
        </Button>
      </div>
      {response && (
        <>
          <div ref={htmlRef}>
            <Card>
              <MarkdownRenderer content={smoothedResponse} />
            </Card>
          </div>
          <div className="flex flex-row items-center gap-2">
            <Button
              onClick={() => {
                window.location.href =
                  "mailto:?subject=Meetingzusammenfassung&body=" +
                  encodeURI(htmlRef.current?.innerText || "");
              }}
              disabled={generating}
            >
              <Email sx={{ mr: 1 }} />
              {t("tools.transcriptSummarizer.sendAsEmail")}
            </Button>

            <Button
              disabled={generating}
              onClick={() => downloadDocument("docx")}
              startDecorator={
                <img src={wordIcon} alt="Word Icon" className="h-5 w-5" />
              }
            >
              {t("downloadAsWord")}
            </Button>
          </div>
        </>
      )}
    </ToolPage>
  );
}
